interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function Input({ ...props }: InputProps) {
  return (
    <input
      className="w-full p-2 text-medium rounded-md bg-inputBackground text-text placeholder:text-text2 focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
      {...props}
    />
  );
}
